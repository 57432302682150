<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="화학물질 작업공정별 관리요령 목록"
          tableId="table"
          :columns="grid.columns"
          :data="grid.data"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" />
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-process-knowhows',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          // {
          //   name: 'plantName',
          //   field: 'plantName',
          //   label: 'LBLPLANT',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width: 120px',
          // },
          {
            name: 'processName',
            field: 'processName',
            label: '작업공정',
            align: 'center',
            style: 'width: 40%',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학물질',
            align: 'center',
            sortable: true,
            style: 'width: 40%',
            type: 'link'
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.chm.knowhow.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '화학물질 작업공정별 관리요령 상세';
      this.popupOptions.param = {
        materialKnowhowId: row ? row.materialKnowhowId : '',
      };
      this.popupOptions.target = () => import(`${'./chemProcessKnowhowDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
